import { IProfile, IRelationship } from '@guider-global/shared-types';
import { Box } from '@mui/material';
import { useLocalization, useReviews } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useProfiles } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { AvatarButton, DropDown, IconButton } from '@guider-global/ui';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import PersonOff from '@mui/icons-material/PersonOff';
import useConcludeRelationship from 'hooks/useConcludeRelationship';
import RelationshipsConcludeDialog from './RelationshipsConcludeDialog';
import ReviewDialog from './ReviewDialog';
import { Reviews } from '@mui/icons-material';
import { interpolate } from 'functions';

interface RelationshipActionsProps {
  relationship: IRelationship;
}

const RelationshipActions = ({ relationship }: RelationshipActionsProps) => {
  const organizationSlug = getSubDomain();
  const { profiles } = useProfiles({});
  const profile = profiles?.at(0);
  const profileId = profile?.id;

  const programSlug = relationship?.programSlug;
  const relationshipId = relationship.id;
  const guideProfile = relationship?.guideProfiles?.at(0) as IProfile;

  const { localeCode } = useLocalization(organizationSlug);
  const { programs } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });

  const { organization } = useOrganization({ organizationSlug });

  const { reviews } = useReviews({});
  const existingReview = !!reviews?.find(
    (review) =>
      review.authorProfile.id === profileId &&
      review.reviewedProfile.id === guideProfile?.id,
  );

  let programType;

  if (programSlug) {
    const program = programs?.find(
      (program) => program.metadata.id.current === programSlug,
    );
    programType = program?.program_details?.program_type;
  } else {
    programType = organization?.open_matching?.program_type;
  }

  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { pathname } = useLocation();
  // Local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);

  // Hooks
  const guideProfiles = relationship?.guideProfiles as IProfile[];
  const guideProfilesIds = guideProfiles?.map((guide) => guide.id) ?? [];
  const isGroupProgram = relationship?.programVariationTypeSlug === 'group';
  const isGuide = guideProfilesIds.includes(profileId ?? '');

  const { concludeRelationship, getModalConfig } = useConcludeRelationship({
    organizationSlug,
    programSlug,
    relationshipId,
    isGroupProgram,
    isGuide,
    programType,
    setIsLoading,
  });

  const handleConcludeRelationship = async () => {
    await concludeRelationship();
  };

  // Events
  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  // Derivatives
  const archived = relationship.isConcluded;

  useEffect(() => {
    if (archived) {
      handleModalClose();
      return;
    }
  }, [handleModalClose, archived, pathname]);

  if (!profile) return <></>;

  const modalConfig = getModalConfig();

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <DropDown
        sx={{ ul: { p: 0 } }}
        anchor={<IconButton variant="regular" icon={<MoreHoriz />} />}
      >
        <AvatarButton
          sx={{ p: 1 }}
          onClick={handleModalOpen}
          orientation="horizontal"
          icon={<PersonOff />}
          size="small"
          heading={modalConfig.heading}
          subtitles={[modalConfig.subtitle]}
          disabled={archived}
        />
        {profileId !== guideProfile?.id && (
          <AvatarButton
            sx={{ p: 1 }}
            onClick={() => setIsReviewModalOpen(true)}
            orientation="horizontal"
            icon={<Reviews />}
            size="small"
            heading={interpolate(
              baseLanguage?.relationships?.review_guide?.button_title,
              {
                firstName: guideProfile?.firstName,
              },
              '',
            )}
            subtitles={[
              baseLanguage?.relationships?.review_guide?.button_subtitle,
            ]}
            disabled={existingReview}
          />
        )}
      </DropDown>

      <RelationshipsConcludeDialog
        dialogTitle={modalConfig.title}
        dialogDescription={modalConfig.description}
        cancelButtonLabel={modalConfig.cancelButtonLabel}
        concludeRelationshipButtonLabel={modalConfig.buttonLabel}
        open={isModalOpen}
        onClose={handleModalClose}
        onConcludeRelationship={handleConcludeRelationship}
        loading={isLoading}
      />
      <ReviewDialog
        open={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        guideProfile={guideProfile}
        profile={profile}
        programType={programType}
        programSlug={programSlug}
      />
    </Box>
  );
};

export default RelationshipActions;
